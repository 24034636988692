import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5565e162 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _25aa1fc0 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _181e1f82 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _1caaf69f = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _4bd9f6fc = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _1f0d8479 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0fb4132a = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _6c1ddece = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _0640787c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _645c15c3 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _8c12b1be = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _03152448 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _ce268212 = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _7d7df791 = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _3f2173a5 = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _6513afec = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _79ec961f = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _084d5c34 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _33f8b7c3 = () => interopDefault(import('../pages/refund/_id.vue' /* webpackChunkName: "pages/refund/_id" */))
const _4aab38a1 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _ae665182 = () => interopDefault(import('../pages/unsubscribe/_hash.vue' /* webpackChunkName: "pages/unsubscribe/_hash" */))
const _4e05fb9f = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _53af401a = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _5565e162,
    name: "index___en"
  }, {
    path: "/et",
    component: _5565e162,
    name: "index___et"
  }, {
    path: "/ru",
    component: _5565e162,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _25aa1fc0,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _181e1f82,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _1caaf69f,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _4bd9f6fc,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _1f0d8479,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _0fb4132a,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _6c1ddece,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _0640787c,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _645c15c3,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _8c12b1be,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _03152448,
    name: "search___en"
  }, {
    path: "/en/tickets",
    component: _ce268212,
    name: "tickets___en"
  }, {
    path: "/et/cart",
    component: _25aa1fc0,
    name: "cart___et"
  }, {
    path: "/et/categories",
    component: _181e1f82,
    name: "categories___et"
  }, {
    path: "/et/cookies",
    component: _1caaf69f,
    name: "cookies___et"
  }, {
    path: "/et/events",
    component: _4bd9f6fc,
    name: "events___et"
  }, {
    path: "/et/login",
    component: _1f0d8479,
    name: "login___et"
  }, {
    path: "/et/logout",
    component: _0fb4132a,
    name: "logout___et"
  }, {
    path: "/et/payment",
    component: _6c1ddece,
    name: "payment___et"
  }, {
    path: "/et/profile",
    component: _0640787c,
    name: "profile___et"
  }, {
    path: "/et/register",
    component: _645c15c3,
    name: "register___et"
  }, {
    path: "/et/restore",
    component: _8c12b1be,
    name: "restore___et"
  }, {
    path: "/et/search",
    component: _03152448,
    name: "search___et"
  }, {
    path: "/et/tickets",
    component: _ce268212,
    name: "tickets___et"
  }, {
    path: "/ru/cart",
    component: _25aa1fc0,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _181e1f82,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _1caaf69f,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _4bd9f6fc,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _1f0d8479,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _0fb4132a,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _6c1ddece,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _0640787c,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _645c15c3,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _8c12b1be,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _03152448,
    name: "search___ru"
  }, {
    path: "/ru/tickets",
    component: _ce268212,
    name: "tickets___ru"
  }, {
    path: "/en/profile/tickets",
    component: _7d7df791,
    name: "profile-tickets___en"
  }, {
    path: "/et/profile/tickets",
    component: _7d7df791,
    name: "profile-tickets___et"
  }, {
    path: "/ru/profile/tickets",
    component: _7d7df791,
    name: "profile-tickets___ru"
  }, {
    path: "/en/categories/:cid",
    component: _3f2173a5,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _6513afec,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _79ec961f,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _084d5c34,
    name: "payment-hash___en"
  }, {
    path: "/en/refund/:id?",
    component: _33f8b7c3,
    name: "refund-id___en"
  }, {
    path: "/en/restore/:key",
    component: _4aab38a1,
    name: "restore-key___en"
  }, {
    path: "/en/unsubscribe/:hash?",
    component: _ae665182,
    name: "unsubscribe-hash___en"
  }, {
    path: "/et/categories/:cid",
    component: _3f2173a5,
    name: "categories-cid___et"
  }, {
    path: "/et/events/:eid",
    component: _6513afec,
    name: "events-eid___et"
  }, {
    path: "/et/groups/:id?",
    component: _79ec961f,
    name: "groups-id___et"
  }, {
    path: "/et/payment/:hash",
    component: _084d5c34,
    name: "payment-hash___et"
  }, {
    path: "/et/refund/:id?",
    component: _33f8b7c3,
    name: "refund-id___et"
  }, {
    path: "/et/restore/:key",
    component: _4aab38a1,
    name: "restore-key___et"
  }, {
    path: "/et/unsubscribe/:hash?",
    component: _ae665182,
    name: "unsubscribe-hash___et"
  }, {
    path: "/ru/categories/:cid",
    component: _3f2173a5,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _6513afec,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _79ec961f,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _084d5c34,
    name: "payment-hash___ru"
  }, {
    path: "/ru/refund/:id?",
    component: _33f8b7c3,
    name: "refund-id___ru"
  }, {
    path: "/ru/restore/:key",
    component: _4aab38a1,
    name: "restore-key___ru"
  }, {
    path: "/ru/unsubscribe/:hash?",
    component: _ae665182,
    name: "unsubscribe-hash___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _4e05fb9f,
    name: "events-eid-tickets___en"
  }, {
    path: "/et/events/:eid/tickets",
    component: _4e05fb9f,
    name: "events-eid-tickets___et"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _4e05fb9f,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _53af401a,
    name: "slug___en"
  }, {
    path: "/et/:slug",
    component: _53af401a,
    name: "slug___et"
  }, {
    path: "/ru/:slug",
    component: _53af401a,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
